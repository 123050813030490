import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Tiora() {
    return {
        "date": <span>2018 <TextInlineLink name={"bs_timeline"} text={"ДТ"} /></span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"} /></span>,
        "habitats": <span>22 млрд. (18 на поверхні)</span>,
        "people": <span><TextInlineLink name={"bs_people_cydonians"} text={"кідоніанці"} />, тіорійці (<TextInlineLink
            name={"bs_spec_humans"} text={"Homo sapiens"} />)</span>,
        "moons": <span>2</span>,
        "g": <span>1.06 земної</span>,
        "atmosphere": <span className='badge bg-success'>придатна для людей</span>,
        "biosphere": <span>тераформована під земну, зіпсована</span>,
        "system": <span>Тіорійський протекторат</span>,
        "sector": <span><TextInlineLink name={"bs_space_fiorella_sector"} text={"Флёр"} /></span>,
        "type": <span>землеподібна, <TextInlineLink name={"bs_universe_planet_types"} text={"промислова"} /></span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бріен"} /></span>,
        "imgs": [{ "short": "bs_wiki_tiora_map_ua", "big": "bs_wiki_oecumene_map_ua" }],
        "text":
            <>
                <h3 className="title-bg">Історія</h3>
                <p className="card-text text-justify">
                    <span className="strong">Тіора</span> - малозначна асоційована
                    (частково незалежна) планета у складі Пріоритету. Знаменита насамперед тим, що
                    місцеві радикальні прихильники незалежності ледь не влаштували геноцид кідоніанців: вони
                    роздобули антиматеріальну ракету і погрожували запустити її на <TextInlineLink
                        name={"bs_planet_cydonia"} text={"столицю країни"} />. В ході операції,
                    санкціонованої <TextInlineLink name={"bs_char_van_gloria_adel"} text={"Адель ван Глорією"} /> і
                    спланованої <TextInlineLink name={"bs_char_de_karma_ivar"} text={"Іваром де Кармою"} />, усі
                    терористи загинули, але разом з ними і багато цивільних.
                </p>
            </>
    }
}

