import { Appearance } from "../../../../../components/appearance";
import { TextPopup, TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Hartrey_kellays() {
    return {
        "has_spoilers": true,
        "spoilers_list": <TextInlineLink name={"book_hyperbeacons"} />,
        "name": "Келлес Хартрі",
        "fullname": <span>Келлес Хартрі</span>,
        "born": <span>68 <TextInlineLink name={"bs_timeline"} text={"ДТ"} /></span>,
        "age": <span>67</span>,
        "homeworld": <span><TextPopup name={"bs_popups_planet_noabia"} /></span>,
        "nation": <span><TextInlineLink name={"bs_people_cydonians"} text={"кідоніанець"} /></span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} /></span>,
        "parents": <span>немає в живих</span>,
        "relatives": <span>не спілкується</span>,
        "work": <span><TextInlineLink name={"bs_comp_esquirete"} text={"Ескваріат"} /> <TextInlineLink
            name={"bs_state_cydonian_priority"} text={"Пріоритету"} /></span>,
        "imgs": [
            { "short": "bs_char_hartrey_kellays_1_png_500", "big": "bs_char_hartrey_kellays_1_jpg_full" },
            { "short": "bs_char_hartrey_kellays_2_jpg_200", "big": "bs_char_hartrey_kellays_2_jpg_full" },
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_hyperbeacons"} />
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"} />
                </div>,
            ],
        "text":
            <>
                <h3 className="title-bg">Юність і робота в КНК</h3>
                <p className="card-text text-justify">
                    <span className="strong">Келлес</span> - уродженець Ноебії, невеликого аграрного світу в
                    секторі <TextInlineLink name={"bs_space_fiorella_sector"} text={"Флер"} />.
                    Батьки Келлеса були інженерами-гідропоніками, а сім'я Хартрі володіла пристойних розмірів бізнесом
                    у рідному світі. З самого дитинства він навчався розбирати техніку: роботів, колісні машини і
                    різного
                    роду фермерські пристрої. І незважаючи на спроби батьків прищепити хлопчикові любов до аграрного
                    господарства, після закінчення школи він покинув планету і вирушив на <TextInlineLink
                        name={"bs_planet_cassini"} text={"Кассіні"} />, вивчати
                    роботу <TextInlineLink name={"bs_tech_grn"} text={"ІнтерМережі"} />.
                </p>
                <p className="card-text text-justify">
                    Його спеціальністю була <span
                        className='code'>«Інформаційна безпека ІнтерМережевих комунікацій»</span> -
                    дуже
                    актуальна професія в <TextInlineLink name={"bs_state_cydonian_priority"} text={"Пріоритеті"} />.
                    Проте, знайти роботу мрії після університету виявилося
                    не так легко. А мріяв Келлес про <TextInlineLink
                        name={"bs_state_cydonian_adimensional_communications"} text={"КНК"} />: туди бажають потрапити всі
                    шанувальники інформаційних технологій у Пріоритеті та <TextInlineLink name={"bs_state_free_worlds"}
                        text={"Самборі"} />, тож
                    величезний конкурс залишив мало
                    шансів
                    хлопцю без досвіду.
                </p>
                <p className="card-text text-justify">
                    Однак Келлес не впав у відчай і вирушив працювати в одну з фірм-підрядників КНК у
                    секторі <TextInlineLink name={"bs_space_grace_sector"} text={"Грація"} />.
                    Там йому доводилося орудувати зварювальним апаратом і стежити за роботами-кабелеукладачами, а зовсім
                    не моніторити безпеку ІТ-інфраструктури,
                    як хлопець мріяв.
                </p>
                <p className="card-text text-justify">
                    Знадобилося кілька років важкої роботи, щоб перейти в рязряд повноцінних інженерів і потрапити
                    до штату КНК. За ці роки Келлес знайшов справжнього друга - Лумара, з яким у майбутньому пропрацював
                    пліч-о-пліч понад три десятиліття. Вони пройшли шлях від зварювальників до інженерів, а потім
                    адміністраторів
                    сегменту ІнтерМережі - вкрай відповідальна і вельми добре оплачувана посада.
                </p>
                <h3 className="title-bg">Служба у Ескваріаті (<TextInlineLink name={"book_hyperbeacons"} />)</h3>
                <p className="card-text text-justify">
                    Приблизно за рік до Таманрасет Келлес і Лумар стали свідками зникнення жителів цілої планети
                    - <TextInlineLink name={"bs_planet_exile_1"} text={"Екзоду-1"} /> в системі <TextInlineLink
                        name={"bs_star_last_exodus"} text={"Останнього Екзоду"} />. Хартрі розлютився від того, що всі
                    проігнорували безумство
                    ситуації, обмежившись лише сухими звітами військових. Хто міг викрасти стільки людей одночасно?!
                </p>
                <p className="card-text text-justify">
                    Бажаючи знайти відповідь, Келлес вирушив на <TextInlineLink name={"bs_planet_cydonia"} text={"Кідонію"} />, прямо до <TextInlineLink name={"bs_comp_esquirete"} text={"Ескваріату Її Величності"} />. Там на нього вже чекала <TextInlineLink name={"bs_char_colbert_simone"} />, яка високо оцінила навички Хартрі і запропонувала йому роботу на уряд Пріоритету. Треба було вирішити завдання, досі непосильне для людей - зрозуміти, як знешкодити і вкрасти маяк гіперзв'язку, який захищений краще за королівський палац.
                </p>
                <p className="card-text text-justify">
                    Працюючи над цим завданням, Келлес став свідком першого <TextInlineLink
                        name={"bs_timeline_tajbennae_incident"} text={"контакту"} /> між людьми та <TextInlineLink
                        name={"bs_spec_vitruvianus"} text={"вітрувіанами"} />,
                    здійсненого <TextInlineLink name={"bs_char_skarsgard_oliver"}
                        text={"Олівером Скошгардом"} /> на <TextInlineLink
                        name={"bs_planet_tajbennae"} text={"Тайбені"} />.
                </p>
            </>
    }
}
