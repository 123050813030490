import { Appearance } from "../../../../../components/appearance";
import { TextInlineLink, TextPopup } from '../../../../../components/cms/wiki_functions';

export function Van_gloria_adel() {
    return {
        "has_spoilers": true,
        "spoilers_list": <><TextInlineLink name={"book_cydonian_girl"} />, <TextInlineLink
            name={"book_hyperbeacons"} /></>,
        "born": <span>71 <TextInlineLink name={"bs_timeline"} text={"ДТ"} /></span>,
        "age": <span>70</span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_cydonia"} /></span>,
        "nation": <span><TextInlineLink name={"bs_people_cydonians"} text={"кідоніанка"} /></span>,
        "name": "Адель ван Глорія",
        "fullname": <span>Адель Бріджит Крістіна ван Глорія</span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} /></span>,
        "work": <span>королева <TextInlineLink name={"bs_state_cydonian_priority"}
            text={"Кідоніанського Пріоритету"} /></span>,
        "imgs": [
            { "short": "bs_char_van_gloria_adel_1_jpg_500", "big": "bs_char_van_gloria_adel_1_jpg_full" },
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_girl"} />
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"} />
                </div>,
            ],
        "text":
            <>
                <h3 className="title-bg">Юність</h3>
                <p className="card-text text-justify">
                    <span className="strong">Адель</span> народилася на Кідонії в родині юристів. Молодість вона провела
                    у приватній школі, тій самій, до якої ходили <TextInlineLink
                        name={"bs_char_de_karma_ivar"} /> і <TextInlineLink name={"bs_char_skarsgard_oliver"} />. Там дівчина
                    мала
                    нещастя пов'язати себе узами дружби з цією родиною. А трохи пізніше, в університетські часи,
                    Адель і зовсім закрутила роман з Іваром, який переріс у довгу та сповнену драми історію.
                </p>
                <p className="card-text text-justify">
                    З юних років Адель тяжіла до соціології, науки менеджменту і, особливо, економіки. Ще в школі
                    вона щільно займалася вивченням складних фінансових теорій і навіть написала статтю про
                    економіку <TextInlineLink name={"bs_space_the_old_space"} text={"Старого космосу"} />: <code>«Земна
                        держава часів <TextInlineLink name={"bs_char_alkanarra_tristan"} text={"Трістана"} /> як модель
                        мілітаристської
                        корпоратократії»</code>. Дівчина мріяла пов'язати своє життя саме з цією сферою.
                </p>
                <p className="card-text text-justify">
                    Навчалася Адель у <TextPopup name={"bs_state_cydonian_priority_science"} text={"БАС"}
                        popup={"Бассіанській академії Співдружності"} />, спочатку в кампусі на
                    рідній
                    Кідонії, а потім на самому місяці <TextInlineLink name={"bs_planet_bassian"} />. Там вона здобула
                    чимало друзів, але ще більше ворогів,
                    серед яких була <TextInlineLink name={"bs_char_de_silgoni_ursula"} /> і навіть <TextInlineLink
                        name={"bs_char_scalligan_lloyd"} />, який на той момент викладав в Академії
                    математику. Уже тоді її гарячі суперечки з викладачами та іншими студентами стали публічними
                    шоу, на які збиралися подивитися тисячі людей. В архівах Басіанського дискусійного клубу
                    збереглися десятки словесних бійок між юною ван Глорією і найрізноманітнішими діячами науки.
                    Не раз під час цих "бесід" народжувалися нові матюки (кілька навіть набули широкого
                    використання і потрапили до словників).
                </p>
                <div className="iteresting-fact">
                    <blockquote>
                        <p className="card-text text-justify">
                            Ми якраз познайомилися в кабінеті шкільного психолога - вона була там частою гостею. І
                            матірний склад
                            вміла загнути такий, що з факультету філології приходили консультуватися
                        </p>
                        <small><TextInlineLink name={"bs_char_de_karma_ivar"} text={"Івар де Карма"} />, <TextInlineLink name={"book_cydonian_girl"} /></small>
                    </blockquote>
                </div>
                <p className="card-text text-justify">
                    З таким підходом Адель ніколи б не здобула вищої освіти, але, як одного разу висловився
                    Скалліган, вона «занадто добре знала, про що говорить». У <TextInlineLink
                        name={"bs_state_cydonian_priority"} text={" Пріоритеті"} /> екзаменацію проводить
                    штучний
                    інтелект, тому ван Глорія, навіть будучи не в ладах з викладачами, без зусиль отримала ступінь.
                    Її бакалаврська робота 38 року ДТ, <code>«Економіка планети-держави як окремий випадок замкнутої
                        финансовой
                        системи де Грасса»</code>, виявилася настільки глибоко опрацьованою, що Адель відразу після
                    випуску запросили до штату викладачів.
                </p>
                <p className="card-text text-justify">
                    Дівчина провела там кілька років і отримала кандидатську з економіки. Потім, зібравши
                    рекомендації, пішла в Королівську податкову інспекцію і повернулася на Кідонію. За цей час встигла
                    початися <TextInlineLink name={"bs_timeline_galactic_war"} text={"Галактична"} /> і весь Пріоритет
                    з жахом стежив за зануренням сусідньої країни в
                    хаос. Адель боялася, що війна докотиться до кідоніанців і що Івара, на той момент її коханого,
                    відправлять на фронт. Так і сталося. За рік до кінця бойових дій, у 26 ДТ, де Карма вирушив
                    на передову.
                </p>
                <p className="card-text text-justify">
                    Після його повернення стосунки вже не були колишніми: Івар, який до цього не знав, що таке справжня
                    війна, повернувся зі зламаною психікою. Він не був пристосований до мирного життя і всупереч
                    вмовлянням
                    ван Глорії обрав шлях бойового офіцера, відмовившись кидати службу. На цьому їхні шляхи розійшлися,
                    хоча
                    і не до кінця: ще багато років вони листувалися і проводили час разом, але нічого серйозного
                    у підсумку не вийшло.
                </p>
                <h3 className="title-bg">Сходження на трон</h3>
                <p className="card-text text-justify">
                    За 19 років до Таманрасетт, коли Адель було всього 52, глобальний ШІ Пріоритету, «Система довіри»,
                    вибрав її як
                    кандидата на престол. Попередній монарх, Іветта Шерідан, позбулася посади за зловживання владою
                    і військові авантюри. Серед них був вступ Пріоритету в Галактичну для допомоги землянам,
                    яку багато хто вважав
                    безглуздою тратою життів, а також коротке, але криваве придушення
                    активності <TextInlineLink name={"bs_comp_feathered_serpent"} text={"Пернатого змія"} /> в <
                        TextInlineLink name={"bs_space_samborian_traverse"} text={" Самборійському траверсі"} />.
                </p>
                <p className="card-text text-justify">
                    Крім Адель до списку кандидатів потрапили і деякі її знайомі, зокрема Скалліган і де Сільоні.
                    Коли перемогла
                    ван Глорія, це знищило будь-які натяки на прощення старих образ. Проти неї моментально
                    сформувалася
                    ціла політична сила, що складалася з колишніх студентів і викладачів БАС. Багато хто з цих людей
                    мали зв'язки і засоби для організованого саботажу її рішень.
                </p>
                <p className="card-text text-justify">
                    В управлінні країною Адель себе не зраджувала. Вона вела різку, безкомпромісну політику, часто
                    грубила членам <TextInlineLink name={"bs_state_cydonian_priority_priumviratas"}
                        text={"Пріумвірату"} /> і навіть лідерам інших держав. Однак
                    знала, як спілкуватися з
                    людьми і як створити собі гарний медійний образ: незважаючи на неприязнь серед вищих чинів країни,
                    усунути її не було можливості через вкрай високе схвалення серед населення.
                </p>
                <p className="card-text text-justify">
                    Крім цього, вона доклала чимало зусиль щодо викорінення кумівства в Пріумвіраті та експертних радах
                    при уряді. Сотні людей позбулися теплого містечка, стабільної зарплати, а деякі ще й
                    свободи. Відділ по боротьбі з посадовими злочинами радів, а ось Адель нажила собі ще більше
                    ворогів.
                </p>
                <p className="card-text text-justify">
                    На момент <TextInlineLink name={"book_cydonian_girl"} text={"«Кідоніанки»"} />, приблизно за рік
                    до Таманрасетт, проти ван Глорії вже відкрито виступали цілі політичні партії. Скалліган
                    взагалі кілька років будував свою риторику виключно на незгоді з королевою, навіть коли це
                    виглядало повним абсурдом.
                </p>
                <p className="card-text text-justify">
                    Тому коли ван Глорія спочатку переконала Пріумвірат допомогти <TextInlineLink
                        name={"bs_char_alkanarra_siel"} text={"Монарху"} /> придушити бунт, а потім різко
                    відмовилася від цього рішення (через таємне прохання де Карми), їй довелося несолодко. Уряд
                    дав дівчині повну свободу дій, проте вона вважала за краще залишитися осторонь і дозволити земній
                    державі остаточно розвалитися. Це підкосило її рейтинги і дало привід засумніватися в
                    адекватності королеви.
                </p>
                <p className="card-text text-justify">
                    При цьому вона не повідомила Пріумвірату про зникнення 171-го прикордонного флоту в
                    секторі <TextInlineLink name={"bs_space_fiorella_sector"} text={" Фльор"} /> і
                    переконала <TextInlineLink name={"bs_state_cydonian_priority_army"}
                        text={"Генштаб Пріоритету"} /> нічого не повідомляти народу. Замість цього
                    королева, користуючись впливом
                    Ескваріата, фактично взяла армію під контроль і повністю засекретила всі подібні інциденти.
                    За межі військового флоту не вийшло навіть масове зникнення людей у <TextInlineLink
                        name={"bs_star_last_exodus"} text={"Останнього Екзоду"} />, хоча
                    розповзання чуток серед цивільних приховати не вдалося.
                </p>
                <p className="card-text text-justify">
                    Вона також санкціонувала таємну операцію, прозвану в майбутньому <TextInlineLink
                        name={"bs_timeline_tajbennae_incident"} text={"«Інцидент на Тайбені»"} /> і приблизно
                    за рік до Таманрасет зробила Пріоритет першою державою в історії, яка вступила в контакт з
                    іншою розумною расою. Контакт і перестрілку.
                </p>
                <p className="card-text text-justify">
                    Після цього вона попросила Івара де Карму про допомогу, оскільки знала про його видатні таланти і
                    репутацію людини, здатної провернути таємну операцію будь-якої складності. Треба було розібратися,
                    що це за прибульці і чого вони хочуть...
                </p>
            </>
    }
}
